import React from 'react';

import './resources.scss';

export interface Resource {
  name: string;
  img: string;
  format: string;
  url: string;
  description: string;
}

interface Props {
  resource: Resource;
}

export const ResourceItem: React.FC<Props> = ({
  resource: { name, img, url, description },
}) => {
  return (
    <div className="col-sm-4 resource d-flex align-items-stretch my-3">
      <div className="card">
        <img className="card-img-top" src={img} alt={name} />
        <div className="card-body">
          <h5 className="card-title">{name}</h5>
          <p className="card-text">{description}</p>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            Visit
          </a>
        </div>
      </div>
    </div>
  );
};
