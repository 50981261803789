import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { ROUTES } from './core/constants';
import { Footer } from './features/common/footer';
import { Main } from './features/main';

export const Root = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={ROUTES.HOME} component={Main} />
    </Switch>
    <Footer />
  </BrowserRouter>
);
