import React from 'react';

import { appSettings } from '../../appSettings';

import './home.scss';

export const Home = () => {
  return (
    <section className="home" id="home">
      <div className="background">
        <div className="container">
          <div className="jumbotron text-center">
            <h2 className="jumbotron-heading">Light the spark</h2>
            <p className="text-description">Boost your love relationship</p>
            <a
              href={appSettings.joinUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="btn btn-secondary">
                Join
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
