import React from 'react';

import { ResourceItem, Resource } from './resource';

const RESOURCES: Resource[] = [
  {
    name: 'This is why',
    img:
      'https://res.cloudinary.com/dcw7kvha3/image/upload/v1591986502/mll/This_is_why.jpg',
    format: 'Podcast',
    url: 'https://thisiswhythepodcast.com/podcast',
    description:
      'Podcast about relationships, why things happen and how to overcome difficulties.',
  },
  {
    name: 'Caitlin V',
    img:
      'https://res.cloudinary.com/dcw7kvha3/image/upload/v1591986502/mll/Caitlin_-_YouTube.png',
    format: 'Youtube',
    url: 'https://www.youtube.com/channel/UCT4rGeDXVKMzeHsTNsLklTw',
    description:
      'Videos on how to improve your sexual experiences with your partner.',
  },
  {
    name: 'This is why you are single',
    img:
      'https://res.cloudinary.com/dcw7kvha3/image/upload/v1591986502/mll/this-is-why-you-are-single.jpg',
    format: 'Book',
    url:
      'https://www.goodreads.com/book/show/26450645-this-is-why-you-re-single',
    description:
      'This incredible book covers the basics on how to date people increasing your self confidence.',
  },
];

export const Resources = () => {
  return (
    <section className="features bg-light">
      <div className="container">
        <div className="d-flex justify-content-center p-5">
          <h2 className="features-title">Hand Picked Resources</h2>
        </div>
        <div className="row pb-5">
          {RESOURCES.map((resource: Resource) => (
            <ResourceItem key={resource.name} resource={resource} />
          ))}
        </div>
      </div>
    </section>
  );
};
