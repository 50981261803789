import React from 'react';

import './join.scss';

interface Props {
  title?: string;
  link?: {
    url: string;
    text: string;
  };
}

export const Join: React.FC<Props> = ({ title, link }) => {
  return (
    <section id="join">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center p-5">
            <h3>{title}</h3>
            {link && (
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                <button type="button" className="btn btn-secondary">
                  {link.text}
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
