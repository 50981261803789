import React from 'react';

import './consulting.scss';

export const Consulting = () => {
  return (
    <section className="consulting bg-light">
      <div className="container">
        <div className="d-flex justify-content-center pt-5">
          <h2 className="consulting-title">Consulting Room</h2>
        </div>
        <div className="row py-3">
          <div className="col-md-6 consulting-asset">
            <div className="consulting-asset-image-container">
              <img
                className="consulting-asset-image"
                src="https://res.cloudinary.com/dcw7kvha3/image/upload/v1595068114/mll/consulting.jpg"
                alt="consulting room"
              />
            </div>
          </div>
          <div className="col-md-6 consulting-description pt-2">
            <p>Are you finding your dating experience difficult?</p>
            <p>
              Are you having trouble in your relationship and you need a fresh
              perspective?
            </p>
            <p>
              We are here to assist you in 1 to 1 sessions. Remember, we can
              offer advice but we are not professionals therapists.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
