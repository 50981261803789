import React from 'react';

export const About = () => {
  return (
    <section className="consulting bg-light">
      <div className="container">
        <div className="d-flex justify-content-center p-5">
          <h2 className="consulting-title">About Us</h2>
        </div>
        <div className="row">
          <div className="col-md-12 mb-5">
            <p>
              MagicLogicLove wants to promote healthy relationships by
              demystifying romantic love so people can be happier in all their
              “love” journey. Please visit our website to find more information.
              If you have any advice or suggestions and you want to share it
              with us please do so by sending an email to
              magiclogiclove@gmail.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
