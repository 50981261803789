import './css/bootstrap.scss';

import * as React from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';

import { Root } from './root';

const tagManagerArgs = {
  gtmId: 'GTM-WV337S8',
};

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById('root');
render(<Root />, rootElement);
