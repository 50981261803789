import React from 'react';

import { Home } from './home/home';
import { Features } from './features/features';
import { Join } from './join/join';
import { Resources } from './resources/resources';
import { Consulting } from './consulting/consulting';
import { About } from './about/about';
import { appSettings } from '../appSettings';

import './main.scss';

export const Main = () => {
  return (
    <>
      <Home />
      <main role="main">
        <section className="franja">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center p-5">
                <h3>Problems finding a healthy relationship?</h3>
              </div>
            </div>
          </div>
        </section>
        <Consulting />
        <Join
          link={{
            text: 'Request a consultation',
            url: 'https://calendly.com/magiclogiclove/30min',
          }}
        />
        <Features />
        <Join
          title="Find your other half"
          link={{ text: 'Join', url: appSettings.joinUrl }}
        />
        <Resources />
        <Join
          title="Discover more"
          link={{
            text: 'Blog',
            url: 'https://medium.com/magic-logic-love',
          }}
        />

        <About />
      </main>
    </>
  );
};