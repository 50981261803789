import React from 'react';

import { FeatureItem } from './feature-item';

import './features.scss';

const FEATURES = [
  {
    title: 'Your assistants',
    description: `What really makes us happy is knowing that you can walk away with that
  person that adds something special to your life. Our goal is to help
  you find that person and maintain it and assist you through the adversities.`,
    img: {
      src:
        'https://res.cloudinary.com/dcw7kvha3/image/upload/v1588253344/mll/people-holding-hands-3921975.jpg',
      alt: 'hands',
    },
  },
  {
    title: 'Personalized methodology',
    description: `We leverage your chances for success with a step by step process
  adapted for your needs. You will be getting support on each stage on the
  road so you know exactly what you have to do.`,
    img: {
      src:
        'https://res.cloudinary.com/dcw7kvha3/image/upload/v1588253840/mll/checklist-steps.jpg',
      alt: 'checklist',
    },
  },
  {
    title: 'Supportive content',
    description:
      'Discover lots of available tools, the latest researched materials and strategies to achieve success. Navigate the world of love with a solid crew.',
    img: {
      src:
        'https://res.cloudinary.com/dcw7kvha3/image/upload/v1588427996/mll/content.jpg',
      alt: 'content',
    },
  },
];

export const Features = () => {
  return (
    <section className="features bg-light">
      <div className="container">
        <div className="d-flex justify-content-center p-5">
          <h2 className="features-title">
            We are here to help <span>YOU</span>
          </h2>
        </div>
        {FEATURES.map((feature) => (
          <FeatureItem key={feature.title} feature={feature} />
        ))}
      </div>
    </section>
  );
};
